import React, {  useState } from "react";
import { useDispatch } from "react-redux";
import { Link} from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "../../components/InputField/InputField";
import Config from "../../Config";
import {  SIGNUP_FAIL, SIGNUP_REQUEST, SIGNUP_SUCCESS } from "../../store/actions";

const SignupForm = ({ t }) => {
    const dispatch = useDispatch()
    const [signupData, setSignupData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmpassword: "",
    })

    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = () => {
        const { firstName, lastName, email, password, confirmpassword } = signupData
        if (!firstName || !lastName || !email || !password || !confirmpassword) {
            toast.error(t("form_fields_are_required"));
            return false;
        }

        if (password.trim() !== confirmpassword.trim()) {
            toast.error(t("new_password_and_confirm_password_do_not_match"));
            return false;
        }
        setIsLoading(true);
        fetch(`${Config.env().API_URL}/sso-register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email, password, firstName, lastName })
        })
            .then((response) => response.json())
            .then(async (response) => {
                if (response.error) {
                    toast.error(response.error.message)
                    return;
                }
                dispatch({ type: SIGNUP_REQUEST });
                dispatch({ type: SIGNUP_SUCCESS, payload: response });
                toast.success("Your account was created successfully!")
                setSignupData({
                    email: "",
                    password: "",
                    confirmpassword: "",
                    firstName: "",
                    lastName: ""
                })
                return Promise.resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: SIGNUP_FAIL,
                    payload: error,
                });
                return Promise.reject(error?.message || "signup failed");
            })
            .finally(async () => {
                setIsLoading(false)
            })
    }

    const handleChange = (evt) => {
        setSignupData({ ...signupData, [evt.target.name]: evt.target.value });
    };
    return (
        <div className="signup__field pt-5">
            <p>{t("create_Account")}</p>
            <InputField
                type="text"
                placeholder={"first_name"}
                name="firstName"
                secure={false}
                onChange={handleChange}
                value={signupData?.firstName}
            />
            <InputField
                type="text"
                placeholder={"last_name"}
                name="lastName"
                secure={false}
                value={signupData?.lastName}
                onChange={handleChange}
            />
            <InputField
                type="email"
                placeholder={"email"}
                name="email"
                secure={false}
                onChange={handleChange}
                value={signupData?.email}
            />
            <InputField
                type="password"
                placeholder={"password"}
                name="password"
                secure={true}
                value={signupData?.password}
                onChange={handleChange}
            />
            <InputField
                type="password"
                placeholder={"confirm_password"}
                name="confirmpassword"
                secure={true}
                value={signupData?.confirmpassword}
                onChange={handleChange}
            />
            <button
                onClick={handleSubmit}
                className="btn btn-primary mt-4"
            >
                {isLoading ? (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                ) : (
                    t("register")
                )}
            </button>
            <Link to="/login" className="d-block text-center pt-3 btn-link">
                {t("back_to_Login")}
            </Link>
        </div>
    )
}

export default SignupForm