var Config = {
  LIMIT: 10,
  PERSIST_SECRET_KEY: "!!davinci-web$$",
  ENVIRONMENT: "STAGING",
  ENVIRONMENTS: {
    LOCAL: {
      API_URL: "http://localhost:5000/api/DaVinciUsers",
      API: "http://localhost:5000",
      GOOGLE_API_KEY: "AIzaSyChNX89QoDSR_WU1-Op3DPfUjcvTd4JOtU",
      BIGCOMMERCE_DEV_PUBKEY: "cbbmiaaw4x5ew3sgxo5ckyxikuw4sae"
    },
    DEVELOPMENT: {
      API_URL: "https://stg-api.davincivaporizer.com/api/DaVinciUsers",
      API: "https://stg-api.davincivaporizer.com",
      GOOGLE_API_KEY: "AIzaSyChNX89QoDSR_WU1-Op3DPfUjcvTd4JOtU",
      BIGCOMMERCE_DEV_PUBKEY: "cbbmiaaw4x5ew3sgxo5ckyxikuw4sae"
    },
    STAGING: {
      API_URL: "https://stg-api.davincivaporizer.com/api/DaVinciUsers",
      API: "https://stg-api.davincivaporizer.com",
      GOOGLE_API_KEY: "AIzaSyChNX89QoDSR_WU1-Op3DPfUjcvTd4JOtU",
      BIGCOMMERCE_DEV_PUBKEY: "cbbmiaaw4x5ew3sgxo5ckyxikuw4sae"
    },
    PRODUCTION: {
      API_URL:
        "https://api.davincivaporizer.com/api/DaVinciUsers",
      API: "https://api.davincivaporizer.com",
      GOOGLE_API_KEY: "AIzaSyChNX89QoDSR_WU1-Op3DPfUjcvTd4JOtU",
      AUTH_API: "https://api.davincivaporizer.com/api/auth",
    },
  },
};

Config.env = () => {
  return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;
