import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import InputField from "../../components/InputField/InputField";
import Config from "../../Config";
import {getMe, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, SET_BIG_COMMERCE_URL, SET_TOKEN} from "../../store/actions";
import useQuery from "../../hooks/useQuery";
import {HOSTS, REGIONS} from "../../utils/constants";

const LoginForm = ({t}) => {
    const dispatch = useDispatch()
    const query = useQuery()

    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
        bigCommerceLoginUrl: "",
        ip: null,
        region: query.get("region")?.toLowerCase() || "us"
    })
    const [isLoading, setIsLoading] = useState(false)


    const getIpfy = () => {
        fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {
            console.log('Your public IP address is: ' + data.ip);
              setLoginData({...loginData, ip:  data.ip});

          })
          .catch(error => {
              console.log("Tracking IP error", error)
          });

    };

    useEffect(() => {
        getIpfy();
    }, []);


    const handleSubmit = () => {
        const {email, password, region, ip } = loginData;
        const referrer = query.get("referrer")

        if (!email || !password) {
            toast.error(t("form_fields_are_required"));
            return false;
        }
        setIsLoading(true)

        fetch(`${Config.env().API_URL}/sso-signin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email, password, ip, region: region?.toUpperCase()})
        })
            .then((response) => response.json())
            .then(async (response) => {
                console.log(response)
                if (response.error) {
                    toast.error("Login failed")
                    return;
                }
                setLoginData({...loginData, bigCommerceLoginUrl: response.bigCommerceLoginUrl});
                dispatch({type: LOGIN_REQUEST});
                dispatch({type: SET_TOKEN, token: response.id});
                if (referrer === "bigcommerce") {
                    document.getElementById("btn-redirect").click()
                }
                dispatch({type: SET_BIG_COMMERCE_URL, payload: response.bigCommerceLoginUrl});
                const user = {...response, region: region?.toLowerCase()}
                dispatch({type: LOGIN_SUCCESS, payload: user, token: user.id});
                await dispatch(getMe(user.id, user.userId));
                localStorage.isAuthenticated = true;
                return Promise.resolve(user);
            })
            .catch((error) => {
                console.log("LOGGING IN...  ", error)
                dispatch({
                    type: LOGIN_FAIL,
                    payload: error,
                });
                return Promise.reject(error?.message || "login failed");
            })
            .finally(async () => {
                setIsLoading(false)
            })

    };

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            handleSubmit();
        }
    };

    const handleChange = (evt) => {
        setLoginData({...loginData, [evt.target.name]: evt.target.value});
    };


    return (
        <>
            <a id="btn-redirect" href={loginData?.bigCommerceLoginUrl} target="_blank"></a>
            <div className="login__field pt-5">
                <InputField
                    type="email"
                    placeholder={"email"}
                    name="email"
                    secure={false}
                    onChange={handleChange}
                    value={loginData?.email}
                    onKeyPress={handleKeypress}
                />
                <InputField
                    type="password"
                    placeholder={"password"}
                    name="password"
                    secure={true}
                    onChange={handleChange}
                    value={loginData?.password}
                    onKeyPress={handleKeypress}
                />

                <div className="region-select">
                    <select
                        id={"region"}
                        name="region"
                        className="bg-transparent input-feild"
                        style={{
                            width: "100%",
                            height: 50,
                            lineHeight: 20,
                            margin: 0,
                            padding: "0px 10px",
                            color: "rgb(255, 255, 255)",
                            border: "1px solid rgb(255, 255, 255)",
                        }}
                        onChange={handleChange}
                        value={loginData?.region}
                    >
                        <option style={{background: "#332d3b"}} value="">{t("region")}</option>
                        {REGIONS.map((region, index) => {
                            return (
                                <option style={{background: "#332d3b"}} value={region.value} key={index}>
                                    {region.label}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <button
                    onClick={handleSubmit}
                    className="btn btn-primary mt-4"
                >
                    {isLoading ? (
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    ) : (
                        t("login")
                    )}
                </button>

                <a href={`${HOSTS[loginData?.region || "us"]}//account/register`} target="_blank" className="d-block text-center pt-3 btn-link">
                    {t("create_Account")}
                </a>

                <div style={{width: "100%", display: "flex", marginTop: 50}}>
                    <a
                        style={{margin: "auto", textAlign: "center",}}
                        href={`${HOSTS[loginData?.region || "us"]}/account/login#recover`}
                        className="fancy-text"
                        target="_blank"
                    >
                        {t("forgot_password")}
                    </a>
                </div>
            </div>
        </>
    )
}

export default LoginForm