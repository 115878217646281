import React, { useState } from 'react'
import { logo } from "../../assets/images";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "../../components/InputField/InputField";
import Config from "../../Config";
import { getMe, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, SET_BIG_COMMERCE_URL, SET_TOKEN } from "../../store/actions";
import useQuery from "../../hooks/useQuery";
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {

    const { t } = useTranslation()
    let history = useHistory();

    const [loginData, setLoginData] = useState({
        accessToken: useQuery().get("accessToken"),
        password: "",
        confirmation: "",
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = () => {
        const { password, confirmation, accessToken } = loginData;

        if (!password || !confirmation) {
            toast.error(t("form_fields_are_required"), { toastId: "form_fields_are_required" });
            return false;
        }
        if(password !== confirmation){
            toast.error(t("passwords_do_not_match"), { toastId: "passwords_do_not_match" });
            return false;
        }
        setIsLoading(true)

        fetch(`${Config.env().API}/reset-password?access_token=${accessToken}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ password, confirmation })
        })
            .then((response) => response.json())
            .then(async (response) => {
                console.log(response)
                if (response.success) {
                    toast.success("Password reset successfully", { toastId: "reset_success" })
                    setLoginData({ ...loginData, password: "", confirmation: "" })
                    history.push("/login")
                } else {
                    toast.error(response.message || response.error.message, { toastId: "reset_failed" })
                }

            })
            .catch((error) => {
                console.log("LOGGING IN...  ", error)
                return Promise.reject(error?.message || "login failed");
            })
            .finally(async () => {
                setIsLoading(false)
            })
    }

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            handleSubmit();
        }
    };

    const handleChange = (evt) => {
        setLoginData({ ...loginData, [evt.target.name]: evt.target.value });
    };


    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center  login banner-primary">
                <div className="login__logo ">
                    <img src={logo} />
                </div>
                <div className="login__field pt-5">

                    <InputField
                        type="password"
                        placeholder={"enter_new_password"}
                        name="password"
                        secure={true}
                        onChange={handleChange}
                        value={loginData?.password}
                        onKeyPress={handleKeypress}
                    />

                    <InputField
                        type="password"
                        placeholder={"confirm_your_password"}
                        name="confirmation"
                        secure={true}
                        onChange={handleChange}
                        value={loginData?.confirmation}
                        onKeyPress={handleKeypress}
                    />

                    <button
                        onClick={handleSubmit}
                        className="btn btn-primary mt-4"
                    >
                        {isLoading ? (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            t("resetPassword")
                        )}
                    </button>

                    <Link to="/login" className="d-block text-center pt-3 btn-link">
                        {t("login")}
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ResetPassword