import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { logo } from "../../assets/images";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import { SocialIcon } from "../../components/index";
import Config from "../../Config";
import { login } from "../../store/actions";
import "./Login.scss";
import LoginForm from "./Form";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoading: false,
      bigCommerceLoginUrl: ""
    };
  }

  componentDidMount() {
    // this.props.apiError("");
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  //   if (isAuthenticated) {
  //     return <Redirect to="/dashboard" />;
  //   }

  // signin = (email, password) => {
  //   return fetch(`${Config.env().AUTH_API}/signin`, {
  //     method: "POST",
  //     body: JSON.stringify({ email, password })
  //   })
  // }

  handleSubmit = () => {
    const { email, password } = this.state;
    const { t } = this.props;

    if (!email || !password) {
      toast.error(t("form_fields_are_required"));
      return false;
    }
    this.setState({ isLoading: true });

    fetch(`${Config.env().API_URL}/sso-signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email, password })
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ bigCommerceLoginUrl: response.data.bigCommerceLoginUrl });
      })
      .catch((error) => {
        console.log("LOGGING IN...  ", error)
      })
      .finally(() => {
        // this.setState({ isLoading: false });
        document.getElementById("btn-redirect").click()
        this.props
          .login(email, password)
          .then(() => {
            toast.success(t("login_success"));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
      })

    // this.props
    //   .login(email, password)
    //   .then(() => {
    //     toast.success(t("login_success"));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     this.setState({ isLoading: false });
    //   });
  };

  handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  render() {
    const { email, password, isLoading } = this.state;
    const { isAuthenticated, firstDeviceSetup, t } = this.props;

    try {
      if (firstDeviceSetup || localStorage.firstDeviceSetup && JSON.parse(localStorage.firstDeviceSetup)) {
        // return <Redirect to="/" />;
      }

      if (isAuthenticated) {
        return <Redirect to="/welcome" />;
      }
    } catch (e) {
      console.log(e);
    }

    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center  login banner-primary">
          <div className="login__logo ">
            <img src={logo} />
          </div>
          <LoginForm t={t} />
          <div className="login__description text-center">
            {/* <ForgotPassword /> */}
            <SocialIcon />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.userAuth.isAuthenticated,
  firstDeviceSetup: state.device.firstDeviceSetup,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(login(email, password)),
  //   forgotPassword: (email) => dispatch(forgotPassword(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login));
